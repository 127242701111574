import { MeetingProvider } from "@videosdk.live/react-sdk";
import { useEffect } from "react";
import { useState } from "react";
import { MeetingAppProvider } from "./MeetingAppContextDef";
import { MeetingContainer } from "./meeting/MeetingContainer";
import { LeaveScreen } from "./components/screens/LeaveScreen";
import { JoiningScreen } from "./components/screens/JoiningScreen";
import { Provider } from "react-redux";

import NavigationBar1 from "./components/NavigationBar1";
import { store } from "./redux/store";

function OnlineApp() {
  const [token, setToken] = useState("");
  const [meetingId, setMeetingId] = useState("");
  const [participantName, setParticipantName] = useState("");
  const [micOn, setMicOn] = useState(false);
  const [webcamOn, setWebcamOn] = useState(false);
  const [customAudioStream, setCustomAudioStream] = useState(null);
  const [customVideoStream, setCustomVideoStream] = useState(null);
  const [isMeetingStarted, setMeetingStarted] = useState(false);
  const [isMeetingLeft, setIsMeetingLeft] = useState(false);

  const isMobile = window.matchMedia(
    "only screen and (max-width: 768px)"
  ).matches;

  useEffect(() => {
    if (isMobile) {
      window.onbeforeunload = () => {
        return "Вы уверены что хотите выйти?";
      };
    }
  }, [isMobile]);

  return (
    <>
      <Provider store={store}>
        <MeetingAppProvider>
          {isMeetingStarted ? (
            <MeetingProvider
              config={{
                meetingId,
                micEnabled: micOn,
                webcamEnabled: webcamOn,
                name: participantName ? participantName : "TestUser",
                multiStream: true,
                customCameraVideoTrack: customVideoStream,
                customMicrophoneAudioTrack: customAudioStream,
              }}
              token={token}
              reinitialiseMeetingOnConfigChange={true}
              joinWithoutUserInteraction={true}
            >
              <MeetingContainer
                onMeetingLeave={() => {
                  setToken("");
                  setMeetingId("");
                  setParticipantName("");
                  setWebcamOn(false);
                  setMicOn(false);
                  setMeetingStarted(false);
                }}
                setIsMeetingLeft={setIsMeetingLeft}
              />
            </MeetingProvider>
          ) : isMeetingLeft ? (
            <LeaveScreen setIsMeetingLeft={setIsMeetingLeft} />
          ) : (
            <JoiningScreen
              participantName={participantName}
              setParticipantName={setParticipantName}
              setMeetingId={setMeetingId}
              setToken={setToken}
              micOn={micOn}
              setMicOn={setMicOn}
              webcamOn={webcamOn}
              setWebcamOn={setWebcamOn}
              customAudioStream={customAudioStream}
              setCustomAudioStream={setCustomAudioStream}
              customVideoStream={customVideoStream}
              setCustomVideoStream={setCustomVideoStream}
              onClickStartMeeting={() => {
                setMeetingStarted(true);
              }}
              startMeeting={isMeetingStarted}
              setIsMeetingLeft={setIsMeetingLeft}
            />
          )}
        </MeetingAppProvider>
      </Provider>
    </>
  );
}

export default OnlineApp;
